@import "../../variables";

body{
    min-width: auto;
}

.footer{
    display: none;
}

.error_page{
    
    .container{
        padding-top: 8rem;
        padding-bottom: 8rem;

        .logo_title{
            margin-bottom: 6rem;

            .logo{
                width: 10.125rem;
                height: 1.6875rem;
                margin-bottom: 0.375rem;
            }
        }

        .error_content{
            p{
                padding-bottom: 2.5rem;
                font-size: 1.625rem;
                font-weight: 700;
            }

            span{
                color: $graytext;
                padding-bottom: 2.875rem;

                @include mobile{
                    font-size: $body-text-12;
                }
            }

            a{
                button{
                    width: 15.625rem;
                    padding: 1rem 0.625rem;
                    border-radius: 0.3125rem;
                    background-color: $buttonblue;
                    color: $white;
                    font-weight: 700;
                    transition: 0.2s;

                    &:hover{
                        background-color: $btnhover;
                    }
                }
            }
        }
    }
}